// Variables
// =========================================================================

$spacer: 30px;

$xs-min: 34em;
$sm-min: 48em;
$md-min: 62em;
$lg-min: 75em;

$xs-viewport: (min-width: #{$xs-min});
$sm-viewport: (min-width: #{$sm-min});
$md-viewport: (min-width: #{$md-min});
$lg-viewport: (min-width: #{$lg-min});

$xs-viewport-down: (max-width: #{$xs-min});
$sm-viewport-down: (max-width: #{$sm-min});
$md-viewport-down: (max-width: #{$md-min});
$lg-viewport-down: (max-width: #{$lg-min});

$color-primary: #6E6EBE;
$color-primary-darker: #333366;
$color-primary-darkest: #0E0E34;
$color-text: #27272E;
$color-text-light: #5C6574;

// Functions
// =========================================================================

/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
@function decimal-round ($number, $digits: 0, $mode: round) {
	$n: 1;

	// $number must be a number
	@if type-of($number) != number {
		@warn '#{ $number } is not a number.';
		@return $number;
	}

	// $digits must be a unitless number
	@if type-of($digits) != number {
		@warn '#{ $digits } is not a number.';
		@return $number;
	} @else if not unitless($digits) {
		@warn '#{ $digits } has a unit.';
		@return $number;
	}

	@for $i from 1 through $digits {
		$n: $n * 10;
	}

	@if $mode == round {
		@return round($number * $n) / $n;
	} @else if $mode == ceil {
		@return ceil($number * $n) / $n;
	} @else if $mode == floor {
		@return floor($number * $n) / $n;
	} @else {
		@warn '#{ $mode } is undefined keyword.';
		@return $number;
	}
}

/**
 * Convert the given PX size to REMs
 */
@function size ($targetSize, $baseSize: 16) {
	@return #{decimal-round($targetSize / $baseSize, 3)}rem;
}

// Mixins
// =========================================================================

@mixin responsiveSize($minFontSize, $maxFontSize, $minScreenWidth: 400, $maxScreenWidth: 1260) {
	$fontDiff: $maxFontSize - $minFontSize;
	$screenDiff: $maxScreenWidth - $minScreenWidth;
	font-size: #{$maxFontSize}px;
	font-size: calc(#{$minFontSize}px + (#{$fontDiff}) * (100vw - #{$minScreenWidth}px) / (#{$screenDiff}));

	@media (min-width: #{$maxScreenWidth}px) {
		font-size: #{$maxFontSize}px;
	}

	@media (max-width: #{$minScreenWidth}px) {
		font-size: #{$minFontSize}px;
	}
}

// Styles
// =========================================================================

* {
	margin: 0;
	padding: 0;

	text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	box-sizing: border-box;
}

html {
	height: 100%;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	scroll-behavior: smooth;

	color: $color-text;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
	font-weight: normal;
	-webkit-text-size-adjust: 100%;
}

body {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100%;
	padding: $spacer;

	@include responsiveSize(14, 16);

	background-image: url("./bg.jpg");
	background-size: cover;

	&:before {
		content: '';
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		display: block;

		background-image: linear-gradient(
			to right,
			fade_out($color-primary, 0.4),
			fade_out($color-primary-darkest, 0.4)
		);
	}

	> div {
		position: relative;
		z-index: 1;
		width: 100%;
		max-width: 755px;
	}
}

h1 {
	font-size: size(40);
	line-height: 1.5em;
}

h2 {
	margin-bottom: $spacer;

	font-size: size(28);
	font-weight: normal;
	line-height: 1.5em;
}

h4 {
	color: $color-text-light;
	font-size: size(16);
}

p {
	font-size: size(14);
	line-height: 1.7em;

	&:not(:last-child) {
		margin-bottom: $spacer/2;
	}
}

a {
	color: $color-primary;
}

.header {
	color: #fff;
	text-align: center;
	margin-bottom: $spacer;
}

.main {
	padding: $spacer*2;

	background-color: #fff;
	box-shadow: 0 6px 16px 0 rgba(0,0,0,0.20);
	border-radius: 5px;
}

.contracts {
	display: flex;
	align-items: stretch;
	justify-content: center;
	margin: $spacer #{-$spacer/2};

	font-size: 0;
	list-style: none;

	li {
		display: flex;
		align-items: stretch;
		width: calc(50% - #{$spacer / 2});
		margin: 0 $spacer/2;
	}

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		padding: 10px;

		color: $color-text-light;
		text-decoration: none;
		text-align: center;

		background-color: #fff;
		border: 1px solid #D2D2D2;
		box-shadow: 0 4px 10px 0 rgba(0,0,0,0.10);
		border-radius: 5px;

		&.signed {
			border-color: #2BC895;

			&[href="#"] {
				pointer-events: none;
			}

			path[fill="#DDDDF8"] { fill: #AAE9D5 }
			path[fill="#6C6CFF"] { fill: #0B9C6D }
			path[fill="#FBF2D8"] { fill: #AAE9D5 }
			path[fill="#FFDA73"] { fill: #0B9C6D }

			span {
				color: #0B9C6D;
				background-color: fade_out(#2BC895, 0.8) !important;
			}

			&.all {
				small {
					color: #0B9C6D;
				}
			}
		}
	}

	svg {
		margin: 20px -15px 20px 0;
		vertical-align: middle;
	}

	p {
		margin: 5px 0;
		font-size: size(13);
		line-height: 1.5em;
	}

	span {
		display: block;
		width: 100%;
		padding: 10px;
		margin-top: 10px;

		color: #fff;
		font-size: size(14);
		font-weight: bold;
		line-height: normal;

		background-color: $color-primary-darker;
		border-radius: 5px;

		transition: background-color 0.15s ease;
	}

	small {
		display: block;
		margin-top: 10px;

		font-size: size(12);
		font-weight: bold;
	}

	a:hover span {
		background-color: $color-primary-darkest;
	}
}
